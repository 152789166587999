.ql-snow .ql-stroke {
  stroke: white;
}

.quill {
  color: #fff;
}

.ql-container {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.react-datepicker {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.react-datepicker__day {
  transition: 160ms all;
}

.react-datepicker__day--today {
  background: #fff;
  color: #000;
}

.react-datepicker__day--today:hover {
  background: #01E8A7;
}

.react-datepicker__day--selected {
  background: #01E8A7;
  color: #000;
  font-weight: 900;
}

.react-datepicker__day:hover {
  background: #01E8A7;
}
