.rbc-calendar {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.rbc-toolbar button {
  color: #34d867;
  cursor: pointer !important;
}

.rbc-btn-group > button {
  transition: 160ms all;
  border-color: #34d867;
}

.rbc-btn-group .rbc-active {
  background: #34d867 !important;
  color: black;
  border-color: black;
  font-weight: 600;
}

.rbc-btn-group > button:hover {
  background: #34d867 !important;
}

.rbc-off-range-bg {
  background: #333333 !important;
}

.rbc-date-cell.rbc-off-range > a {
  color: #CDCDCD !important;
}

.rbc-day-bg.rbc-today {
  background: #6b6b6b !important;
}

.rbc-event {
  background: transparent !important;
  font-size: 0.8rem !important;
  width: 90% !important;
  margin: auto !important;
}

.rbc-event-content:hover {
  white-space: normal !important;;
}

.rbc-show-more {
  background: none !important;
  color: #34d867;
  padding: 4px 8px;
}

.rbc-overlay-header {
  color: #1B1B1B;
  font-weight: 900;
}

.rbc-overlay .rbc-event {
  margin-bottom: 4px !important;
}
